<template lang="pug">
include ../../../configs/template.pug
v-dialog(v-model="activeModal" max-width="700")
  template(#activator="{ on }")
    v-btn(v-on="on" fab x-small icon)
      v-icon mdi-cog-transfer-outline
  v-card.pa-2
    v-card-title
      v-tabs(v-model="tabs")
        v-tab {{ $t('addDocument') }}
        v-tab(v-if="sailorDocument.has_related_docs") {{ $t('editing') }}
    v-card-text
      v-tabs-items(v-model="tabs")
        v-tab-item.pt-2
          div.w-100.px-0
            +select-validation('attachDocumentBody.dependency', 'sailorDocument.status_dkk.documents', 'notExistsDocs', '"document_descr"', '["required"]')(
              return-object
              clearable
              @keyup.enter="checkValidation"
              @change="getDependencies")
          div.w-100.px-0
            +select-validation('attachDocumentBody.object_id', 'availableAttachedDocuments', 'addDocument', '"name"', '["required"]')(
              :disabled="!attachDocumentBody.dependency"
              :loading="isDependencyLoading"
              clearable
              @keyup.enter="checkValidation")
        v-tab-item.pt-2
          div.w-100.px-0
            +select-validation('replaceDocumentBody.old_document', 'sailorDocument.status_dkk.exists_docs', 'changeDocFrom', '', '["required"]')(
              :item-text="item => item.type_doc + ' - ' + item.number + ' ' + item.info"
              return-object
              clearable
              @keyup.enter="checkValidation"
              @change="getDependencies")
          div.w-100.px-0
            +select-validation('replaceDocumentBody.new_document', 'availableReplaceDocuments', 'changeDocTo', '"name"', '["required"]')(
              :disabled="!replaceDocumentBody.old_document"
              :loading="isDependencyLoading"
              clearable
              @keyup.enter="checkValidation")
    div.w-100.mt-3
      v-btn(color="darken-1" text @click="activeModal = false").mr-3 {{ $t('setReject') }}
      v-btn(:loading="isLoading" color="success" @click="checkValidation") {{ $t('save') }}
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { sailorStatementSQCTableChanges } from '@/mixins/validationRules'
import { SUCCESS_CODE } from '@/configs/constants'

const initAttachDocumentBody = () => ({
  object_id: null,
  dependency: null
})
const initReplaceDocumentBody = () => ({
  old_document: null,
  new_document: null
})

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      availableAttachedDocuments: [],
      availableReplaceDocuments: [],
      attachDocumentBody: initAttachDocumentBody(),
      replaceDocumentBody: initReplaceDocumentBody(),
      tabs: null,
      activeModal: false,
      isLoading: false,
      isDependencyLoading: false
    }
  },
  computed: {
    ...mapGetters(['rankByID', 'educationProfessionByID', 'positionMedicalByID', 'typeDocQualificationByID']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  validations () { return sailorStatementSQCTableChanges(this) },
  methods: {
    ...mapActions(['getListCertificateEti', 'getMedicalCertificates', 'getEducationDocs', 'getQualificationDiplomaProofs',
      'getQualifications', 'attachNewDocumentsToStatementSQC', 'replaceDocumentsInStatementSQC']),

    async getDependencies (value) {
      if (this.tabs === 0) {
        this.attachDocumentBody.object_id = null
        this.availableAttachedDocuments = []
      } else {
        this.replaceDocumentBody.new_document = null
        this.availableReplaceDocuments = []
      }
      if (!value) return false

      this.isDependencyLoading = true
      let response
      switch (value.content_type) {
        case 'certificateeti':
          response = await this.getListCertificateEti()
          SUCCESS_CODE.includes(response?.code) && response.data.results.map(item => {
            item.name = `ID: ${item.id} — №${item.ntz_number || item.number || item.other_number} — ${item.ntz[this.labelName]}`
          })
          break
        case 'medicalcertificate':
          response = await this.getMedicalCertificates(new URLSearchParams({ page_size: 1000, status_document: 19 }))
          SUCCESS_CODE.includes(response?.code) && response.data.results.map(item => {
            item.name = `ID: ${item.id} — №${item.number} — ${this.positionMedicalByID(item.position)[this.labelName]}`
          })
          break
        case 'education':
          response = await this.getEducationDocs(new URLSearchParams({ page_size: 1000, status_document: 2 }))
          SUCCESS_CODE.includes(response?.code) && response.data.results.map(item => {
            item.name = `ID: ${item.id} — №${item.number_document} — ${this.educationProfessionByID(item.speciality || item.qualification)[this.labelName]}`
          })
          break
        case 'proofofworkdiploma':
          response = await this.getQualificationDiplomaProofs(new URLSearchParams({ page_size: 1000, status_document: 19 }))
          SUCCESS_CODE.includes(response?.code) && response.data.results.map(item => {
            item.name = `ID: ${item.id} — ${this.rankByID(item.rank)[this.labelName]}`
          })
          break
        case 'qualificationdocument':
          response = await this.getQualifications({ params: { page_size: 1000, status_document: 19 } })
          SUCCESS_CODE.includes(response?.code) && response.data.results.map(item => {
            item.name = `ID: ${item.id} — ${this.typeDocQualificationByID(item.type_document)[this.labelName]} — ${this.rankByID(item.rank)[this.labelName]}`
          })
          break
      }
      if (SUCCESS_CODE.includes(response?.code)) {
        const data = response.data.results || response.data
        if (this.tabs === 0) {
          // Show only those documents that are not yet in the application
          this.availableAttachedDocuments = data
            .filter(item => !this.sailorDocument.status_dkk.exists_docs.some(doc => doc.id === item.id))
        } else this.availableReplaceDocuments = data.filter(item => item.id !== value.id)
      }
      this.isDependencyLoading = false
    },

    checkValidation () {
      if (this.$v.$invalid) return this.$v.$touch()
      if (this.tabs === 0) this.attachNewDocument()
      else this.replaceDocument()
    },

    async attachNewDocument () {
      const body = { ...this.attachDocumentBody }
      body.content_type = body.dependency.content_type
      body.dependency = body.dependency.id

      this.isLoading = true
      const response = await this.attachNewDocumentsToStatementSQC({ ...this.$route.params, body })
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$notification.success('editedDocument')
        this.activeModal = false
        this.attachDocumentBody = initAttachDocumentBody()
        this.$v.$reset()
      }
      this.isLoading = false
    },

    async replaceDocument () {
      const body = { ...this.replaceDocumentBody }
      body.content_type = body.old_document.content_type
      body.old_document = body.old_document.id

      this.isLoading = true
      const response = await this.replaceDocumentsInStatementSQC({ ...this.$route.params, body })
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$notification.success('editedDocument')
        this.activeModal = false
        this.replaceDocumentBody = initReplaceDocumentBody()
        this.$v.$reset()
      }
      this.isLoading = false
    }
  }
}
</script>
